import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import Breadcrumbs from './Breadcrumbs';

import { Back, Close } from '../Common/ControlButtons';
import { useRouteMatch } from 'react-router-dom';
import {
  BreadcrumbsHeader as Wrapper,
  BreadcrumbsWrapper,
  BreadcrumbsTitle,
} from '../../primitives/styles/Breadcrumbs/BreadcrumbsStyles';
import { LogoIconContainer } from '../../primitives/styles/HeaderBar/HeaderBarStyles';
import { RippleLogoIcon } from '../../primitives/media/Icons';

interface BreadcrumbsProps {
  isPublishInProgress?: boolean;
  currentStep: number;
  totalSteps: number;
  flow?: string
  onBack?: () => void
  onClose?: () => void
  shouldRenderBack?: boolean;
  shouldRenderRippesIcon?: boolean;
  shouldRenderClose?: boolean;
}

const BreadcrumbsHeader: React.FC<BreadcrumbsProps> = ({
  isPublishInProgress,
  currentStep,
  totalSteps,
  flow,
  onBack,
  onClose,
  shouldRenderBack = true,
  shouldRenderRippesIcon = false,
  shouldRenderClose = true
}) => {

  const { path } = useRouteMatch();
  const flowName = flow || path.split('/')[2];

  const getTitle = () => {
    switch (flowName) {
      case 'upload':
        return '1cb31c6d-f90c-4f9b-9c09-180f65c5c327';
      case 'publish':
        return '74425868-32cb-403d-aaa3-5d620257da2b';
      case 'delete':
        return 'c4a68a4f-b2c7-43b3-af57-a9dcf323b36d';
      case 'frames':
        return '1452763c-175c-431c-a358-e8baa694eb02';
      case 'createFrame':
        return '48d53a01-84c5-4686-a01f-a8a1558135d7';
      case 'publishFrames':
        return 'ddf75c2a-78e9-4fdc-9352-be90103e0270';
      case 'qrcode':
        return '62dce59b-0fc7-4519-aacb-d8cf1484abd7';
      case 'channels': 
        return '2d9a7a7a-4d8f-4f79-bc1e-fc5d6a915690';
      case 'create':
        return '33dd1863-a93c-489d-b877-449583c03d2b';
      case 'location':
        return '19ea661d-2e65-4bff-a033-82f52f1df05e';
      case 'createLocation':
        return '4c2e8106-8793-41d5-b8a9-8ef86cae8c12';
      case 'aboutYourPlace':
        return 'f56e1463-d105-418c-b4ab-e5016cb4f243';
      default:
        return '';
    }
  };

  const getDefaultTitle = () => {
    switch (flowName) {
      case 'upload':
        return 'Upload design';
      case 'publish':
        return 'Publish design';
      case 'delete':
        return 'Delete design';
      case 'frames':
        return 'Frames';
      case 'createFrame':
        return 'Create Frame';
      case 'publishFrames':
        return 'Publish Frame';
      case 'qrcode':
        return 'QR code';
      case 'channels':
        return 'Manage channels';
      case 'create':
        return 'Create design';
      case 'location':
        return 'Location';
      case 'createLocation':
        return 'Create location';
      case 'aboutYourPlace':
        return 'About your place';
      default:
        return '';
    }
  };

  return (
    <Wrapper>
      {!isPublishInProgress && <Back shouldDisplay={shouldRenderBack} onBack={onBack} currentStep={currentStep} />}
      <BreadcrumbsWrapper>
        <BreadcrumbsTitle>
          <Phrase
            translationKey={getTitle()}
            defaultText={getDefaultTitle()}
          />
          {shouldRenderRippesIcon && <LogoIconContainer>
            <RippleLogoIcon/>
            </LogoIconContainer>}
        </BreadcrumbsTitle>
        {totalSteps > 1 && (
          <Breadcrumbs totalSteps={totalSteps} currentStep={currentStep} />
        )}
      </BreadcrumbsWrapper>
      {!isPublishInProgress && <Close flowName={flowName} onClose={onClose} shouldDisplay={shouldRenderClose} />}
    </Wrapper>
  );
};

export default BreadcrumbsHeader;
