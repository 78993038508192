import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

export enum OnBoardingStates {
    MOBILE_PORTAL_LOCATION = 'mobile_portal_location',
    MOBILE_PORTAL_CONTENT = 'mobile_portal_content',
    MACHINE_CLOUD_ACCOUNT =  'machine_cloud_account',
    MACHINE_LOCATION = 'machine_location',
    MACHINE_LICENSE = 'machine_license',

};

const setMachineBySerial = (serial: string, onboardingState: OnBoardingStates, organizationId?: string) => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/makers/machines/${serial}/onboarding`;
    
      return axios.put(url, {onboardingState, orgId: organizationId}).then(data => data);
}

export default setMachineBySerial;
