import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

const getMachineBySerial = (serial: string) => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/makers/machines/${serial}?supportDetails=true`;
    
      return axios.get(url).then(data => data);
}

export default getMachineBySerial;
