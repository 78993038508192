import TextControl from "./TextControl";
import ImageControl from "./ImageControl";
import { FrameEditable } from "../../../primitives/types/FrameTemplate";
import { FrameControls, FrameControlsHeader } from "../../../primitives/styles/Frames/FrameControlsStyle";
import Phrase from "../../../components/Phrase/Phrase";
import { CloseWrapper } from '../../../primitives/styles/Common/CommonStyle';
import { CloseIcon } from '../../../primitives/media/Icons';
import FontSelector from "./FontSelector";
import { useState, useEffect } from "react";
import FloatingButton from '../../../components/Common/FloatingButton';



interface ControlsProps {
  editables: FrameEditable[];
  setEditables: (editables: FrameEditable[]) => void
  closePopup: () => void
}

const Controls: React.FC<ControlsProps> = ({editables, setEditables, closePopup}) => {

  const [selectedFont, updateSelectedFont] = useState("");
  const [editablesTemp, setEditablesTemp] = useState<FrameEditable[]>([]);
  const [hasTextControl, setHasTextControl] = useState(false);

  useEffect(() => {
    setEditablesTemp(editables);
  
    editables.forEach(e => {
      if (e.font) {
        updateSelectedFont(e.font);
      }
      if (e.type === 'text' || e.type === 'radialText') {
         setHasTextControl(true);
      }
    });    
  }, [editables]);


  const changeFont = (selectedFont: string) => {
    const updatedData = editablesTemp.map((editable: FrameEditable) => { 
      if (editable.type === 'text' || editable.type === 'radialText') {
        return {...editable, font: selectedFont}
      }
      return editable;
    });
    setEditablesTemp(updatedData);
    updateSelectedFont(selectedFont);
  }


  const onChange = (index: number, changed: FrameEditable) => {
    const updatedData = [...editablesTemp];
    updatedData[index] = changed;
    setEditablesTemp(updatedData);
  }

  const doneEditing = () => {
    setEditables(JSON.parse(JSON.stringify(editablesTemp)));
    closePopup();
  }


  return (
    <FrameControls>
      <FrameControlsHeader>
        <Phrase translationKey='9b542d83-ca1b-47c4-8a44-a9f4f2399ad0' defaultText='Edit Frame'></Phrase>
        <CloseWrapper id="frame-controls-close" onClick={closePopup}>
          <CloseIcon/>
        </CloseWrapper>
      </FrameControlsHeader>
      {hasTextControl && 
        <FontSelector selectedFont={selectedFont} updateSelectedFont={changeFont}></FontSelector>
      }
      {editablesTemp.map((e, i) => {
          switch (e.type) {
            case 'radialText':
            case 'text':
                return (<TextControl data={e} key={i} index={i} updateControl={onChange}></TextControl>)
            case 'image':
                return (<ImageControl data={e} key={i} index={i} updateControl={onChange}></ImageControl>)
            default:
              return (<></>)
          }           
        })}

      <div style={{display: 'flex', justifyContent: 'center'}}>
        <FloatingButton
          onClick={doneEditing}
          isDisabled={false}
          variant={'contained'}
          position={'sticky'}
          buttonText={'0d24354a-d507-44d3-aaf3-a4c017b62907'} // Next
          buttonDefaultText={"I'm Done"}
        />
      </div>
     
    </FrameControls>
  );
}

export default Controls;
