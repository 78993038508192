import Loading from '../../components/Loading/Loader';
import getConfigByEnv from '../../utils/getConfigByEnv';
import WizardLinksContainer from '../Wizard/WizardLinksContainer';
import SelectModal from '../../components/SelectModal/SelectModal';
import ConnectionError from '../../components/Errors/ConnectionError';
import DesignLimitError from '../../components/Errors/DesignsLimitError';
import HomepageHeaderBar from '../../components/HomepageHeader/HomepageHeaderBar';
import SuccessNotification from '../../components/SuccessNotification/SuccessNotification';
import NoOrgError from '../../components/NoOrgError/NoOrgError';

import qs from 'querystring';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { gqlClient } from '../../utils/gqlClient';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { UserDetails } from '../../primitives/types/User';
import { Organization } from '../../primitives/types/User';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Wrapper } from '../../primitives/styles/Home/HomeStyle';
import { Location as LocationType } from '../../primitives/types/Location';
import {
  SelectFilesLimitError,
  SetFilesLimitError,
} from '../../store/ripplesSlice';

import {
  SetLocations,
  SelectLocations,
  SetSelectedLocation,
  SetInitLocationState,
} from '../../store/locationSlice';

import {
  SelectNotification,
  ResetNotificationsState,
} from '../../store/notificationsSlice';

import {
  GET_LOGIN_DETAILS,
  GET_INITIAL_LOCATION_DETAILS,
  GET_LOGIN_DETAILS_WITH_ROLES,
} from '../../api/graphQl/query/getLoginDetails';

import {
  ResetFlowState,
  ResetRipplesState,
  ResetChannelState,
  ResetLocationState,
  ResetManageChannelState,
} from '../../store/cleanup';

import {
  SetOrgId,
  SetOrgName,
  SetOrgServiceLevel,
  SelectOrgId,
  SelectUserId,
  SelectOrgName,
  SetCurrentViewer,
  SelectIsAuthorized,
  SelectOrganizations,
  SelectOrgServiceLevel,
} from '../../store/userSlice';
import _ from 'lodash';
import NoPermissionsError from '../../components/Errors/NoPermissionsError';
import { useTheme } from 'styled-components';

const LOCATIONS_PATH = 'viewer.organizationScope.locations.edges';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isOrgSelected, setIsOrgSelected] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [goToWebappModal, setGoToWebappModal] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState<boolean | undefined>();
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false) 

  const userId = useSelector(SelectUserId);
  const selectedOrgId = useSelector(SelectOrgId);
  const locations = useSelector(SelectLocations);
  const selectedOrgName = useSelector(SelectOrgName);
  const selectedOrgServiceLevel = useSelector(SelectOrgServiceLevel);
  const isAuthorized = useSelector(SelectIsAuthorized);
  const filesLimitError = useSelector(SelectFilesLimitError);
  const organizations: Organization[] = useSelector(SelectOrganizations);
  const { name: notification, clientWarningMessage } = useSelector(
    SelectNotification
  );

  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const { logout } = useAuth0();

  const handleCloseConnectionError = () => {
    setConnectionError(false);
    window.location.reload();
  };

  const onConnectionError = () => {
    setConnectionError(true);
    setIsLoading(false);
  };

  const cleanupStore = () => {
    dispatch(ResetFlowState());
    dispatch(ResetFlowState());
    dispatch(ResetRipplesState());
    dispatch(ResetChannelState());
    dispatch(ResetLocationState());
    dispatch(ResetManageChannelState());
    window.flow.isInProgress = false;
  };

  const getUserDetails = () => gqlClient.query(GET_LOGIN_DETAILS, {});

  const getUserRoles = (scope: string) =>
    gqlClient.query(GET_LOGIN_DETAILS_WITH_ROLES, {
      scope,
    });

  const getInitialLocationDetails = (selectedOrgId: string) =>
    gqlClient.query(GET_INITIAL_LOCATION_DETAILS, { orgId: selectedOrgId });

  // prevents initial page scroll down
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    cleanupStore();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { org } = qs.parse(location.search.substring(1));
    if (org) history.push(`portal/onboarding${location.search}`);
  }, []);

  useEffect(() => {
    if (isAuthorized && !userId) {
      setIsLoadingUser(true)
      getUserDetails()
        .then(({ data }) => data.viewer)
        .then(({ isLoggedIn, me: userDetails, theScope }) => {
          const scope = userDetails.defaultScope;
          const user: UserDetails = { isLoggedIn, ...userDetails };
          dispatch(SetCurrentViewer(user));
          if (user.organizations && user.organizations.length === 1) {
            const org = user.organizations[0];
            handleSelectOrganization(org);
          }
          return getUserRoles(scope);
        })
        .then(({ data }) => data.viewer)
        .then(({ theScope }) => {
          const roles = _.get(theScope, 'scopeRoles');
          // user with 'buyer' role cannot login to this site.
          const canLogin =
            Array.isArray(roles) && roles.some((role) => role.order <= 4);
            setIsUserBlocked(!canLogin)
            setIsLoadingUser(false)
            setIsLoading(false);
        })
        .catch((err: Error) => {
          setIsLoadingUser(false)
          if (err.message === 'Network Error') {
            onConnectionError();
          } else {
            setHasError(err.message);
          }
                });
    }
    if (userId) {
      setIsLoading(false);
    }
    //eslint-disable-next-line
  }, [userId, isAuthorized]);

  // initial locations load with loader UI/UI
  useEffect(() => {
    if (!locations && selectedOrgId) {
      setLoadingLocations(true);
      getInitialLocationDetails(selectedOrgId)
        .then(({ data }) => get(data, LOCATIONS_PATH))
        .then((locations) => handleReceivedLocations(locations))
        .catch((err: Error) => {
          if (err.message === 'Network Error') {
            onConnectionError();
          } else {
            setHasError(err.message);
          }
        });
    }
    //eslint-disable-next-line
  }, [locations, selectedOrgId]);

  // periodical silent locations load without loader UI/UX
  useEffect(() => {
    if (selectedOrgId) {
      setIsOrgSelected(true);
      getInitialLocationDetails(selectedOrgId)
        .then(({ data }) => get(data, LOCATIONS_PATH))
        .then((locations) => handleReceivedLocations(locations))
        .catch((err: Error) => {
          if (err.message === 'Network Error') {
            onConnectionError();
          } else {
            setHasError(err.message);
          }
        });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timeout = clientWarningMessage ? 5500 : 2200;
    const resetNotificationTimeout = setTimeout(() => {
      dispatch(ResetNotificationsState());
    }, timeout);

    return () => {
      clearTimeout(resetNotificationTimeout);
    };

    //eslint-disable-next-line
  }, [notification, clientWarningMessage]);

  const handleReceivedLocations = (rawLocations: any) => {
    const locations: LocationType[] = rawLocations.map(
      ({ node }: { node: any }) => ({ ...node })
    );
    if (locations.length === 1) {
      dispatch(SetSelectedLocation(locations[0]));
    }
    dispatch(SetLocations(locations));
    setLoadingLocations(false);
  };

  const handleSelectOrganization = ({
    name,
    id,
    serviceLevel,
  }: {
    name: string | null;
    id: string | null;
    serviceLevel: number | null;
  }) => {
    dispatch(SetOrgId(id));
    dispatch(SetOrgName(name));
    dispatch(SetOrgServiceLevel(serviceLevel));

    setIsOrgSelected(true);
    dispatch(SetInitLocationState());
  };

  const handleSelectLocation = ({ shortId }: { shortId: string }) => {
    const { WEBAPP_URL } = getConfigByEnv().PORTAL;
    window.location.replace(`${WEBAPP_URL}/?code=${shortId}`);
  };

  const openChooseOrg = () => {
    setIsOrgSelected(false);
  };

  const handleCloseFilesLimitError = () => {
    dispatch(SetFilesLimitError(false));
  };

  const handleNoPermsissionsErrorClick = () => logout();

  if (isLoading || isLoadingUser) return <Loading />;
  if (isUserBlocked) return <NoPermissionsError onClose={handleNoPermsissionsErrorClick} theme={theme}/>

  if (!organizations)
    return (
      <>
        <HomepageHeaderBar
          orgName=""
          disabled={false}
          hasSingleOrg={true}
          openChooseOrg={() => {}}
          openGoToWebappModal={() => {}}
          hideGoToWebapp={true}
        />
        <Wrapper>
          {' '}
          <NoOrgError></NoOrgError>
        </Wrapper>
      </>
    );

  if (hasError) return <div> OMG!!! ... {hasError}</div>;

  const shouldRenderWizards =
    Boolean(selectedOrgId) &&
    isOrgSelected &&
    !filesLimitError &&
    !loadingLocations;

  const hasSingleOrg: boolean = organizations.length === 1;
  const shouldRenderNotificationPopUp = Boolean(notification);
  const shouldRenderSelectOrganization =
    (!hasSingleOrg &&
      !selectedOrgId &&
      !connectionError &&
      organizations.length) ||
    !isOrgSelected;

  return (
    <>
      <HomepageHeaderBar
        orgName={selectedOrgName}
        disabled={loadingLocations}
        hasSingleOrg={hasSingleOrg}
        openChooseOrg={openChooseOrg}
        openGoToWebappModal={() => setGoToWebappModal(true)}
      />
      {/* {shouldRenderSelectOrganization ? (
        <SelectOrganization
          orgId={selectedOrgId}
          organizations={organizations}
          handleSelectOrganization={handleSelectOrganization}
          closeSelectOrganizationModal={() => setIsOrgSelected(false)}
        />
      ) : (
        <Wrapper>
          {loadingLocations && <Loading/>}
          {shouldRenderWizards && <WizardLinksContainer />}
          {filesLimitError && <DesignLimitError onClose={handleCloseFilesLimitError}/>}
        </Wrapper>
      )} */}
      {shouldRenderSelectOrganization ? (
        <SelectModal
          options={organizations}
          defaultTitle={'Select organization'}
          onSelectOption={handleSelectOrganization}
          shouldPreviewCloseButton={!!selectedOrgId}
          title={'95357955-4491-4342-9945-c9121580f1ad'}
          closeSelectModal={() => setIsOrgSelected(true)}
        />
      ) : (
        <Wrapper>
          {loadingLocations && <Loading />}
          {shouldRenderWizards && <WizardLinksContainer orgServiceLevel={selectedOrgServiceLevel}/>}
          {filesLimitError && (
            <DesignLimitError onClose={handleCloseFilesLimitError} />
          )}
        </Wrapper>
      )}
      {shouldRenderNotificationPopUp && (
        <SuccessNotification
          notification={notification}
          clientWarningMessage={clientWarningMessage}
        />
      )}
      {goToWebappModal && (
        <SelectModal
          options={locations}
          shouldPreviewCloseButton={true}
          defaultTitle={'Select location'}
          onSelectOption={handleSelectLocation}
          title={'8f1e8b35-ac69-4002-ba7b-25bed0ee345d'}
          closeSelectModal={() => setGoToWebappModal(false)}
          noOptionsTitle={'19e39749-2e01-4b62-9d58-057371800d98'}
          defaultNoOptionsTitle={'No active locations found'}
        />
      )}
      {connectionError && (
        <ConnectionError
          error={hasError}
          onClose={handleCloseConnectionError}
        />
      )}
    </>
  );
};

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <Loading />,
});
